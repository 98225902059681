import React from 'react'

const EditIconSvg = () => {
    return (
        <div className="edit-wrapper__icon">
          <svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px" viewBox="0 0 20 20" overflow="visible" xmlSpace="preserve">
            <g>
              <rect id="Rectangle_2880" x="3.6" y="4.6" fill="#FFFFFF" width="13" height="11"/>
              <path id="Subtraction_28" fill="#0000FF" d="M13.7,17.4H6.3c-2,0-3.7-1.7-3.7-3.7V6.3c0-2,1.7-3.7,3.7-3.7h7.5c2,0,3.7,1.7,3.7,3.7
              v7.5C17.4,15.8,15.8,17.4,13.7,17.4L13.7,17.4z M5.5,11.6c-0.3,0-0.5,0.2-0.5,0.5v2.1c0,0,0,0.1,0,0.1c0,0.2,0.2,0.4,0.5,0.4h8.7
              c0.2,0,0.4-0.2,0.4-0.4c0,0,0-0.1,0-0.1V12c0-0.1,0-0.2-0.1-0.3c-0.2-0.2-0.5-0.2-0.6,0c-0.1,0.1-0.1,0.2-0.1,0.3v1.7H6V12
              C6,11.8,5.8,11.6,5.5,11.6L5.5,11.6z M7.6,9C7.3,9,7.1,9.2,7.1,9.5c0,0.1,0.1,0.3,0.2,0.4l2.2,2.2l0,0c0.1,0.1,0.2,0.1,0.3,0.1h0h0
              h0.1c0,0,0,0,0.1,0h0h0c0.1,0,0.2-0.1,0.3-0.2l0,0c0,0,0,0,0,0l2.2-2.2c0.2-0.2,0.2-0.5,0-0.8C12.4,9.1,12.3,9,12.1,9
              c-0.1,0-0.3,0.1-0.4,0.2l-1.3,1.3V5.6c0-0.1-0.1-0.3-0.2-0.4C10.1,5.1,10,5.1,9.9,5.1c-0.3,0-0.5,0.2-0.5,0.5l0,0v4.9L8,9.2
              C7.9,9.1,7.8,9,7.6,9z"/>
            </g>
          </svg>
        </div>
    )
}

export default EditIconSvg;