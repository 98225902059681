import React from 'react'

const EditSettingsSvg = () => {
    return (
        <div className="edit-wrapper__icon">
          <svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
	          x="0px" y="0px" viewBox="0 0 90 90" overflow="visible" xmlSpace="preserve">
            <g id="Group_4005" transform="translate(-1795 -489)">
              <g transform="matrix(1, 0, 0, 1, 1795, 489)">
                <circle id="Ellipse_674-2" fill="#FFF9FA" cx="45" cy="45" r="42"/>
              </g>
              <path id="Subtraction_62" fill="#0000FF" d="M1840,576c-23.2,0-42-18.8-42-42c0-16.9,10.1-32.1,25.6-38.7c21.4-9,46,1,55.1,22.4
                c9,21.4-1,46-22.4,55C1851.2,574.9,1845.6,576,1840,576z M1831.4,552.2L1831.4,552.2c0.9,0.5,1.9,0.9,2.9,1.2v3.6
                c0,0.7,0.5,1.4,1.2,1.5c3.2,0.7,6.6,0.7,9.8,0c0.7-0.1,1.2-0.8,1.2-1.5v-3.6c1-0.3,2-0.7,2.9-1.2l2.5,2.5c0.5,0.5,1.3,0.6,1.9,0.2
                c2.8-1.8,5.1-4.2,6.9-6.9c0.4-0.6,0.3-1.4-0.2-1.9l-2.5-2.5c0.5-0.9,0.9-1.9,1.2-2.9h3.6c0.7,0,1.4-0.5,1.5-1.2
                c0.7-3.2,0.7-6.6,0-9.8c-0.1-0.7-0.8-1.2-1.5-1.2h-3.6c-0.3-1-0.7-2-1.2-2.9l2.5-2.5c0.5-0.5,0.6-1.3,0.2-1.9
                c-1.8-2.8-4.2-5.1-6.9-6.9c-0.6-0.4-1.4-0.3-1.9,0.2l-2.5,2.5c-0.9-0.5-1.9-0.9-2.9-1.2V512c0-0.7-0.5-1.4-1.2-1.5
                c-3.2-0.7-6.6-0.7-9.8,0c-0.7,0.1-1.2,0.8-1.2,1.5v3.6c-1,0.3-2,0.7-2.9,1.2l-2.5-2.5c-0.5-0.5-1.3-0.6-1.9-0.2
                c-2.8,1.8-5.1,4.2-6.9,6.9c-0.4,0.6-0.3,1.4,0.2,1.9l2.5,2.5c-0.5,0.9-0.9,1.9-1.2,2.9h-3.6c-0.7,0-1.4,0.5-1.5,1.2
                c-0.7,3.2-0.7,6.6,0,9.8c0.1,0.7,0.8,1.2,1.5,1.2h3.6c0.3,1,0.7,2,1.2,2.9l-2.5,2.5c-0.5,0.5-0.6,1.3-0.2,1.9
                c1.8,2.8,4.2,5.1,6.9,6.9c0.6,0.4,1.4,0.3,1.9-0.2L1831.4,552.2z M1840.5,555.9c-1,0-2-0.1-3.1-0.2v-3.4c0-0.7-0.5-1.3-1.1-1.5
                c-1.5-0.4-3-1-4.3-1.8c-0.6-0.4-1.4-0.3-1.9,0.2l-2.4,2.4c-1.6-1.2-3.1-2.7-4.3-4.3l2.4-2.4c0.5-0.5,0.6-1.3,0.2-1.9
                c-0.8-1.4-1.4-2.8-1.8-4.3c-0.2-0.7-0.8-1.2-1.5-1.1h-3.4c-0.3-2-0.3-4.1,0-6.1h3.4c0.7,0,1.3-0.5,1.5-1.1c0.4-1.5,1-3,1.8-4.3
                c0.4-0.6,0.3-1.4-0.2-1.9l-2.4-2.4c1.2-1.6,2.7-3.1,4.3-4.3l2.4,2.4c0.5,0.5,1.3,0.6,1.9,0.2c1.4-0.8,2.8-1.4,4.3-1.8
                c0.7-0.2,1.2-0.8,1.2-1.5v-3.4c2-0.3,4.1-0.3,6.1,0v3.4c0,0.7,0.5,1.3,1.1,1.5c1.5,0.4,3,1,4.3,1.8c0.6,0.4,1.4,0.3,1.9-0.2
                l2.4-2.4c1.6,1.2,3.1,2.7,4.3,4.3l-2.4,2.4c-0.5,0.5-0.6,1.3-0.2,1.9c0.8,1.4,1.4,2.8,1.8,4.3c0.2,0.7,0.8,1.1,1.5,1.1h3.4
                c0.3,2,0.3,4.1,0,6.1h-3.4c-0.7,0-1.3,0.5-1.5,1.2c-0.4,1.5-1,3-1.8,4.3c-0.4,0.6-0.3,1.4,0.2,1.9l2.4,2.4
                c-1.2,1.6-2.7,3.1-4.3,4.3l-2.4-2.4c-0.5-0.5-1.3-0.6-1.9-0.2c-1.4,0.8-2.8,1.4-4.3,1.8c-0.7,0.2-1.1,0.8-1.1,1.5v3.4
                C1842.5,555.9,1841.5,555.9,1840.5,555.9L1840.5,555.9z M1840.5,526.8c-4.2,0-7.7,3.4-7.7,7.7c0,4.2,3.4,7.7,7.7,7.7
                s7.7-3.4,7.7-7.7l0,0C1848.1,530.3,1844.7,526.8,1840.5,526.8L1840.5,526.8z M1840.5,539.1c-2.5,0-4.6-2.1-4.6-4.6
                c0-2.5,2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6l0,0C1845.1,537,1843,539.1,1840.5,539.1L1840.5,539.1z"/>
            </g>
          </svg>
        </div>
    )
}

export default EditSettingsSvg;