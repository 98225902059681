import React from 'react'

const EditBackgroundSVG = () => {
    return (
        <div className="edit-wrapper__icon">
          <svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" viewBox="0 0 25 25" overflow="visible" xmlSpace="preserve">
            <path id="Subtraction_61" fill="#0000FF" d="M17.8,22.9H7.2c-2.9,0-5.2-2.3-5.2-5.2V7.2c0-2.9,2.3-5.2,5.2-5.2h10.5
              c2.9,0,5.2,2.3,5.2,5.2v10.5C22.9,20.6,20.6,22.9,17.8,22.9z M6.2,14.7c-0.4,0-0.6,0.3-0.6,0.6v3c0,0,0,0.1,0,0.1
              c0,0.3,0.3,0.6,0.6,0.6h12.2c0.3,0,0.6-0.2,0.6-0.5c0-0.1,0-0.1,0-0.2v-3c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2
              c-0.4,0-0.6,0.3-0.6,0.6v2.4H6.8v-2.4C6.8,15,6.6,14.7,6.2,14.7z M11.7,7.1V14c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2
              c0.4,0,0.7-0.3,0.7-0.7V7.1L15.1,9c0.1,0.1,0.3,0.2,0.5,0.2c0.4,0,0.7-0.3,0.7-0.7c0-0.2-0.1-0.4-0.2-0.5L13,4.8l0,0
              c-0.1-0.1-0.3-0.2-0.4-0.2h0h0h-0.1c0,0-0.1,0-0.1,0h0h0c-0.2,0-0.3,0.1-0.4,0.2l0,0c0,0,0,0,0,0L8.8,7.9C8.5,8.2,8.5,8.7,8.8,9
              C9,9.1,9.1,9.2,9.3,9.2c0.2,0,0.4-0.1,0.5-0.2L11.7,7.1L11.7,7.1z"/>
            </svg>
        </div>
    )
}

export default EditBackgroundSVG;